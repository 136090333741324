export const ERROR_STATUS_UNAUTHORIZED = 401;
export const ERROR_STATUS_NOT_CONFIRM = 403;
export const ERROR_STATUS_UNPROCESSABLE = 422;
export const ERROR_STATUS_NOT_FOUND = 404;
export const ERROR_STATUS_NOT_ENOUGH_CREDITS = 402;
export const ERROR_STATUS_INTERNAL = 500;
export const STATUS_SUCCESS = 200;

export const MODERATION_ERROR_CODE = 19000;

export const GALLERY_SHOW_ENABLED = 2;
export const ICEBREAKER_USED = 1;

export const USER_VERIFIED = 1;
export const USER_NOT_VERIFIED = 10;

export const FREE_MSG_ENABLED = 0;
export const FREE_MSG_SENT = 1;
export const FREE_MSG_DISABLED = 2;

export const GENDER_MALE = 1;
export const GENDER_FEMALE = 2;

export const POST_REG_FUNNEL_SHOW = 1;
export const POST_REG_FUNNEL_SHOW_REQUIRED = 2;
export const REGISTRATION_CONFIRMED = 1;
export const SEARCH_LIMIT = 60;

export const BILLING_REQUEST_STATUS_SUCCESS = 0;
export const BILLING_REQUEST_STATUS_ERROR = 1;

export const PRESENTS_ORDER_REQUEST_STATUS_SUCCESS = 0;
export const PRESENTS_ORDER_REQUEST_STATUS_ERROR = 1;
export const BILLING_REQUEST_AUTHORISATION_ERROR = 2;
export const BILLING_REQUEST_VALIDATION_ERROR = 3;

export const EMAIL_VERIFIED = 2;

export const APP_MOBILE = "mobile";
export const APP_DESKTOP = "desktop";

export const IS_ONLINE = 10;
export const IS_OFFLINE = 1;

export const STREAM_LIVE = 10;
export const STREAM_NOT_LIVE = 0;

export const FAVORED = 1;
export const NOT_FAVORED = 10;

export const MAX_USER_AGE = 90;
export const MIN_USER_AGE = 18;

export const WINKED_STATUS = 1;
export const NOT_WINKED_STATUS = 10;

export const LIKED_STATUS = 1;
export const NOT_LIKED_STATUS = 10;

export const TYPE_PHOTO = 1;
export const TYPE_VIDEO = 2;

export const IMAGE_PRIVATE = 1;
export const IMAGE_PUBLIC = 0;

export const PURCHASE_TYPE_FREE = 1;
export const PURCHASE_TYPE_PAY = 2;

export const DISPUTE_STATUS_NEW = 1;
export const DISPUTE_STATUS_RESOLVED = 2;
export const DISPUTE_STATUS_REJECTED = 3;
export const MAX_LENGTH_TEXTAREA = 500;

export const GOOGLE_API_GENDER_MALE = "male";

export const CONST_REG_TYPE_SIMPLE = 0;
export const CONST_REG_TYPE_GOOGLE = 2;

/**
 * F profile
 * @type {number}
 */
export const M_PROFILE = 1;
/**
 * Sinple profile
 * @type {number}
 */
export const NM_PROFILE = 10;

export const VIEW_FROM_SEARCH = "view_search_profile";

export const CONST_USERNAME_MIN_SYMBOLS = 2;
export const CONST_USERNAME_MAX_SYMBOLS = 255;
export const USERNAME_REGEX = /^[a-zA-Z\s'’`-]+$/;
export const OCCUPATION_MAX_SIZE = 255;
export const BIO_MAX_SIZE = 1000;
export const PROFILE_MEDIA_STATUS_ACTIVE = 1;
export const PROFILE_MEDIA_STATUS_CONVERT = 3;

export const SUPPORT_MESSAGE_COUNTER = 0;

export const LIVE_CHAT_SCRIPT_SRC = "//fw-cdn.com/12163049/4656722.js";
